
/*
     FILE ARCHIVED ON 00:00:30 Oct 23, 2013 AND RETRIEVED FROM THE
     INTERNET ARCHIVE ON 13:12:53 Jul 28, 2022.
     JAVASCRIPT APPENDED BY WAYBACK MACHINE, COPYRIGHT INTERNET ARCHIVE.

     ALL OTHER CONTENT MAY ALSO BE PROTECTED BY COPYRIGHT (17 U.S.C.
     SECTION 108(a)(3)).
*/
/*
playback timings (ms):
  captures_list: 3687.61
  exclusion.robots: 0.072
  exclusion.robots.policy: 0.065
  cdx.remote: 0.075
  esindex: 0.009
  LoadShardBlock: 48.876 (3)
  PetaboxLoader3.datanode: 76.139 (5)
  CDXLines.iter: 21.848 (3)
  load_resource: 175.945 (2)
  PetaboxLoader3.resolve: 103.629 (2)
*/

.App {
  text-align: center;
}

.wrapper {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.game-item {
  border: #333 solid 1px;
  margin: 16px;
}
.game-item:hover {
  background: #f8f8f8;
}

.thumbnail {
  display: none;
}

footer {
    background: #000;
    color: #fff;
    padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

header {
    position: relative;
    z-index: 15;
    background: #fff;
    -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 1px 10px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1);
    box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 1px 10px rgb(0 0 0 / 10%);
    height: 44px;
    width: 100%;
    padding-top: 0;
}
.wrapper {
    max-width: 982px;
    width: 100%;
    margin: 20px auto;
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@import url(https://web.archive.org/web/20131124233512cs_/http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700);

body {
  font-family: "Helvetica Neue",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
}
h1, h2, h3 {
  font-family: "Source Sans Pro","Helvetica Neue",Arial,sans-serif;
}
a {
  color: #468aca;
  text-decoration: none;

  -webkit-transition: color 250ms ease-out;
  -moz-transition: color 250ms ease-out;
  -o-transition: color 250ms ease-out;
  -ms-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
}
a:hover {
  color: #3f729b;
  text-decoration: underline;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
h3 {
  color: #666;
  font-size: 21px;
  font-weight: 600;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
}
.wrapper {
  margin: 0 auto;
  max-width: 982px;
  width: 100%;
  margin: 20px auto;
}
img {
/* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.main {
}
.main-content {
  border: #ddd solid 1px;
  border-top: none;
  background: #fcfcfc;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.main-inner {
  padding: 30px;
}
#header .wrapper {
    width: 984px;
}
#header nav {
  font-size: 18px;
  float: right;
  text-align: right;
  padding-top: 0;
  margin-top: 10px;
}
#header ul {
  margin: 0;
  padding: 0;
}
#header ul li {
  display: inline;
  padding-left: 15px;
}
#header ul li a {
  color: #666;
  font-size: 13px;
  font-weight: 600;
}
#header ul li a:hover {
  color: #111;
  text-decoration: none;
}

.top-bar {
  position: relative;
  z-index: 15;
  background: #fff;
  -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1);
  height: 44px;
  width: 100%;
  padding-top: 0;
}
.top-bar nav {
  padding-top: 10px;
}
.top-bar .logo {
  margin-top: 9px;
  height: 26px;
}


.thumbnail {
  position: relative;
  /*-webkit-box-shadow: 0 0 1px 1px rgba(0,0,0,.08);
  -moz-box-shadow: 0 0 1px 1px rgba(0,0,0,.08);
  box-shadow: 0 0 1px 1px rgba(0,0,0,.08);*/
}
.thumbnail b {
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: absolute;
  display: block;
  left:0;
  right:0;
  bottom:0;
  top:0;

  -webkit-transition: background-color 250ms ease-out;
  -moz-transition: background-color 250ms ease-out;
  -o-transition: background-color 250ms ease-out;
  -ms-transition: background-color 250ms ease-out;
  transition: background-color 250ms ease-out;
}
.thumbnail b:hover {
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.thumbnail .duration {
  position: absolute;
  display: inline-block;
  right: 2px;
  bottom: 2px;
  opacity: 0.75;
  filter: alpha(opacity=75);
  background: #000;
  color: #fff;
  font-size: 11px;
  line-height: 11px;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 2px;
}
.thumbnail .thumbnail-play {
    position: absolute;
    display: none;

    top: 62px;
    top: 20px;
    right: 0px;

    width: 26px;
    height: 20px;

    margin-left: -13px;
    margin-top: -15px;

    font-size: 16px;
    text-align: center;

    opacity: 1;

    color: rgba(255,255,255,0.6);
    text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}

/* Cool Ribbon Effect */
.ribbon.selected {
  position: relative;
}
/*.ribbon.selected .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #ccc transparent transparent transparent;
  bottom: -6px;
}
.ribbon.selected .ribbon-content:after {
  right: 0;
  border-width: 5px 10px 0 0;
}
.ribbon.selected:last-child .ribbon-content:after {
  top: -6px;
  border-color: transparent transparent #ccc transparent;
  border-width: 0 10px 5px 0;
  bottom: auto;
}*/
/* End Cool Ribbon Effect */


/* Stream Player */
.stream-view {
  padding: 30px 20px;
  border-bottom: #eee solid 1px
}
.stream-view h3 {
  margin-top: 0;
  margin-bottom: 15px;
}
.stream-view .stream-container {
  position: relative;
  /*background: #fff;
  -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.05), 0 1px 10px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.05), 0 1px 10px rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.05), 0 1px 10px rgba(0,0,0,0.1);
  /*background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f4f4f4), to(#f8f8f8));
  background-image: -moz-linear-gradient(center top, #f4f4f4 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(#f4f4f4, #f8f8f8);*/
}
.stream-view .stream-player {
    position: relative;
    width: 59%;
    height: auto;
    background: #000;
}
.stream-view .stream-player .stream-video {
    width: 100%;
    padding-bottom: 66.666667%;

    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
}
.stream-view .stream-playlist-container {
    position: absolute;
    width: 41%;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;

    /*box-shadow: inset 0 0 4px -1px rgba(0,0,0,0.5);*/
    /*border-bottom: #ccc solid 1px;*/

    /*-webkit-box-shadow: inset 0 4px 4px -2px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 4px 4px -2px rgba(0,0,0,0.1);*/
    /*box-shadow: 0 0 0 1px rgba(0,0,0,0.1), inset -12px 0 10px 0 rgba(0,0,0,0.1);*/

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.stream-view .stream-playlist {
    list-style: none;
    margin: 0;
    padding: 0;
}
.stream-view .stream-item {
  position: relative;
  width: 100%;
  height: 90px;
  padding: 0;

  /*background: #f8f8f8;

  border-top: #fafafa solid 1px;
  border-bottom: #f4f4f4 solid 1px;

  /*background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f4f4f4), to(#f8f8f8));
  background-image: -moz-linear-gradient(center top, #f4f4f4 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(#f4f4f4, #f8f8f8);*/

  /*border-bottom: 1px solid #eee;*/
  /*-webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.05);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.05);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.05);*/

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.stream-view .stream-item:last-child {
    height: 72px;
}
.stream-view .stream-item:last-child .stream-wrapper {
    padding-bottom: 0;
}
/*.stream-view .stream-item.selected .stream-wrapper, .stream-view .stream-item:nth-child(4) .stream-wrapper {
    border-bottom: none;
}*/
.stream-view .stream-item .stream-wrapper {
    padding: 0 15px 20px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*-webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.05);
    -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.05);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.05);*/
}
.stream-view .stream-item:hover {
  cursor: hand;
  cursor: pointer;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.stream-item .thumbnail .thumb-wrap {
  width: 100%;
  height: 250px;
  margin: 0;
  margin-top: -225px;
  display: table;
}
.stream-item .thumbnail .thumb-clip {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.stream-view .stream-item.selected {
  /*background: #fff;*/
}
.stream-view .stream-item.selected b {
  background: none;
}
.stream-view .stream-item.selected .thumbnail-play {
    display: inline-block;
}
.stream-view .stream-item .thumbnail {
    float: left;
    width: 124px;
    height: 70px;
    overflow: hidden;

    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
}
.stream-view .stream-item .item-meta {
    height: 100%;
    margin-left: 135px;
}
.stream-view .stream-item img {
  float: left;
  width: 100%;
}
.stream-view .stream-item h4 {
  font-size: 13px;
  color: #468aca;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 1);

  padding: 5px 0 0 0;
  margin: 0;
  width: auto;
  max-height: 32px;
  overflow: hidden;

  -webkit-transition: color 250ms ease-out;
  -moz-transition: color 250ms ease-out;
  -o-transition: color 250ms ease-out;
  -ms-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
}
.stream-view .stream-item.selected h4 {
  color: #3f729b;
}
.stream-view .stream-item:hover h4 {
  color: #3f729b;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.stream-view .stream-item .item-meta span {
  display: block;
  font-size: 11px;
  line-height: 14px;
  color: #999;
  margin: 7px 0 0 0;
  font-weight: normal;

  -webkit-transition: color 250ms ease-out;
  -moz-transition: color 250ms ease-out;
  -o-transition: color 250ms ease-out;
  -ms-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
}
.stream-view .stream-item:hover .item-meta span {
  color: #777;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.stream-view .stream-item.selected .item-meta span {
  color: #777;
}
.stream-view .stream-item .item-meta span a {
  color: #777;
}
.stream-view .stream-item:hover .item-meta span a {
  color: #468aca;
}
.stream-view .stream-item:hover .item-meta span a:hover {
  text-decoration: underline;
}
.stream-view .stream-item:hover b {
  background-color: rgba(255, 255, 255, 0.1);
}
.stream-view .stream-item.selected:hover b {
  background: none;
}
.stream-view .stream-item:hover h4 {
}
.stream-view iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.stream-view .player-span {
    width: 100%;
}
/* End Stream Player */

/* Game Item Card */
.games-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.games-list:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  font-size: 0.01em !important;
  line-height: 0.01em !important;
}
.game-item {
  float: left;
  text-align: center;

  position: relative;

  /*width: 142px;*/
  width: 16.666666%;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
.game-item:hover {
  cursor: pointer;
  cursor: hand;
}
.game-item .thumbnail {
  display: none;
}
.game-item a {
  display: block;

  color: auto;
  text-decoration: none;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;

  margin: 0 10px 15px;
  padding: 10px;

  /*-webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 10px rgba(0,0,0,0.1);

  background: #fdfdfd;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdfdfd), to(#f8f8f8));
  background-image: -moz-linear-gradient(center top, #fdfdfd 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(#fdfdfd, #f8f8f8);*/
}
.game-item a:hover {
  color: auto;
  text-decoration: none;
}
.game-item:hover b {
  background-color: rgba(255, 255, 255, 0.1);
}
.game-item .icon {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 20%;
    border-radius: 20%;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    /*-webkit-box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);
    box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);*/
    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
}
.game-item h4 {
  font-size: 13px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  color: #468aca;
  word-wrap: break-word;

  margin: 10px 0 0 0;
  width: 100%;
  overflow: hidden;

  -webkit-transition: color 250ms ease-out;
  -moz-transition: color 250ms ease-out;
  -o-transition: color 250ms ease-out;
  -ms-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
}
.game-item:hover h4 {
  color: #3f729b;
  text-decoration: underline;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.game-item .info {
  text-decoration: none;
  font-size: 11px;
  color: #999;
  margin: 5px 0 0 0;

  -webkit-transition: color 250ms ease-out;
  -moz-transition: color 250ms ease-out;
  -o-transition: color 250ms ease-out;
  -ms-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
}
.game-item:hover .info {
  color: #777;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.game-item .thumbnail {
  width: 100%;
  padding-bottom: 100%;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.game-item .thumbnail b {
  -webkit-border-radius: 20%;
  border-radius: 20%;
  border: none;
}
/* End Game Item Card */

/* Video Card */
.video-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.video-list:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  font-size: 0.01em !important;
  line-height: 0.01em !important;
}
.video-list .empty {
  margin-top: 20px;
  text-align: center;
  color: #ddd;
}

.video-item {
  position: relative;
  float: left;
  width: 33.333333%;
}
.video-item:hover {
  cursor: hand;
  cursor: pointer;
}
.video-item .link {
  display: block;
  margin: 15px 10px;

  /*-webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);*/

  /*background: #fcfcfc;
  -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);*/
}
.video-item a {
  color: auto;
  text-decoration: none;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.video-item a:hover {
  color: auto;
  text-decoration: none;
}
.video-item .metadata {
  /*background: #fdfdfd;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdfdfd), to(#f8f8f8));
  background-image: -moz-linear-gradient(center top, #fdfdfd 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(#fdfdfd, #f8f8f8);*/

  background: #fff;

  -webkit-box-shadow: inset 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.1), 0 1px 10px rgba(0,0,0,0.1);

  border-radius: 0 0 4px 4px;

  display: block;
  height: 53px;
}
.video-item:hover .metadata {
}
.video-item h4 {
  font-size: 13px;
  color: #468aca;
  line-height: 16px;

  margin: 0;
  padding: 10px 10px 0 55px;
  overflow: hidden;

  -webkit-transition: color 250ms ease-out;
  -moz-transition: color 250ms ease-out;
  -o-transition: color 250ms ease-out;
  -ms-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
}
.video-item:hover h4 {
  color: #3f729b;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.video-item .info {
  color: #999;
  -webkit-transition: color 250ms ease-out;
  -moz-transition: color 250ms ease-out;
  -o-transition: color 250ms ease-out;
  -ms-transition: color 250ms ease-out;
  transition: color 250ms ease-out;
}
.video-item:hover .info {
  color: #777;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.video-item:hover b {
  background-color: rgba(255, 255, 255, 0.1);
}
.video-item .thumbnail {
  width: 100%;
  padding-bottom: 56.25%;
  display: block;
  overflow: hidden;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  -webkit-box-shadow: 0 1px 6px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 1px 6px rgba(0,0,0,0.5);
  box-shadow: 0 1px 6px rgba(0,0,0,0.5);
}
.video-item .thumb {
  width: 100%;
  position: absolute;
}
.video-item .icon {
  position: absolute;
  bottom: 22px;
  left: 17px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  -webkit-border-radius: 6px;

  -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.5);
}
.video-item .info {
  display: block;
  font-size: 11px;
  color: #999;
  margin: 5px 0 10px 55px;
}
.video-item span a {
  color: #999;
}
.video-item:hover span a {
  color: #468aca;
}
.video-item:hover span a:hover {
  text-decoration: underline;
}
/* End Video Card */


/* Load More Button */
.load-more {
  background: #fff;
  /*background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdfdfd), to(#f8f8f8));
  background-image: -moz-linear-gradient(center top, #fdfdfd 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(#fdfdfd, #f8f8f8);*/

  text-align: center;
  font-size: 13px;
  font-weight: bold;

  -webkit-transition: background 250ms ease-out;
  -moz-transition: background 250ms ease-out;
  -o-transition: background 250ms ease-out;
  -ms-transition: background 250ms ease-out;
  transition: background 250ms ease-out;
}
.load-more:hover {
  background: #fcfcfc;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.load-more a {
  display: block;
  width: 100%;
  padding: 20px 0;
  color: #468aca;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}
.load-more a:hover {
  color: auto;
  text-decoration: none;
}
.load-more a img {
  display: none;
  opacity: 0.5;
  width: 15px;
  height: 15px;
  padding: 0px 5px 0 0;
  vertical-align: bottom;
}
.load-more a p {
  display: inline;
  margin: 0;
  padding: 0;
}
.load-more span {
  display: block;
  width: 100%;
  padding: 20px 0;
  color: #ddd;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  background: #fafafa;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.load-more-span {
  text-align: center;
  margin: 15px 12px 0 12px;
  -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  border-top: none;

  border-radius: 4px;
  -webkit-border-radius: 4px;
}
/* Load More Button */

/* Light Player */
#light-player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: scroll;
}
#light-player .opacity {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 101;
}
#light-player .inner {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -225px;
}
#light-player .wrapper {
  width: 940px;
  margin: 0 auto;
}
#light-player .item {
  z-index: 102;
}
#light-player .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
}

#light-player .watch-left-arrow {
  left: -100px;
}
#light-player .watch-right-arrow {
  right: -100px;
}
#light-player .watch-left-arrow, #light-player .watch-right-arrow {
  font-family: 'times new roman';
  display:inline-block;
  position: absolute;
  color: #aaa;
  font-size: 60px;
  font-weight: bold;
  cursor: pointer;
  padding: 0 50px;
  height: 256px;
  padding-top: 205px;
  line-height: 60px;
  transform:scale(0.5,1); /* W3C */
  -webkit-transform:scale(0.5,1); /* Safari and Chrome */
  -moz-transform:scale(0.5,1); /* Firefox */
  -ms-transform:scale(0.5,1); /* IE 9 */
  -o-transform:scale(0.5,1); /* Opera */

  -webkit-transition: color 200ms ease-out;
  -moz-transition: color 200ms ease-out;
  -o-transition: color 200ms ease-out;
  -ms-transition: color 200ms ease-out;
  transition: color 200ms ease-out;
}
#light-player .watch-left-arrow:hover, #light-player .watch-right-arrow:hover {
  color: #777;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
/* End Light Player */


.close {
  float: right;
  font-size: 21px;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
  border: none;
  background: none;
}
.close:hover {
  text-decoration: none;
  cursor: pointer;
}
.close img {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 20px;
    height: 20px;
    background: rgba(0,0,0,0.8);
    border-radius: 12px;

  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
.close:hover img {
    background: rgba(0,0,0,0.7);

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}


/* Footer */
.root footer {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 20px;
}
.root footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.root footer li {
  display: inline-block;
  margin: 0 10px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  font-size: 13px;
}
.root footer li a {
  color: #468aca;
}
.root footer li a:hover {
  color: #3f729b;
  text-decoration: underline;
}

/* Tooltip from Twitter Bootstrap */
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  font-size: 12px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: visible;
}

.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-top-color: #000000;
  border-width: 5px 5px 0;
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-top-color: #000000;
  border-width: 5px 5px 0;
}

.tooltip.top-right .tooltip-arrow {
  right: 5px;
  bottom: 0;
  border-top-color: #000000;
  border-width: 5px 5px 0;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-right-color: #000000;
  border-width: 5px 5px 5px 0;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-left-color: #000000;
  border-width: 5px 0 5px 5px;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #000000;
  border-width: 0 5px 5px;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-bottom-color: #000000;
  border-width: 0 5px 5px;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-bottom-color: #000000;
  border-width: 0 5px 5px;
}
/* End Tooltip from Twitter Bootstrap */

a.btn, .btn {
    border:1px solid #0000FF;
    border-top:1px solid #0000FF;
    border-left:1px solid #0000FF;
    border-right:1px solid #0000FF;
    border-radius:5px;
    -webkit-border-radius:5px;
    box-shadow:0 1px 1px 0 rgba(0,0,0,0.4);
    -webkit-box-shadow:0 1px 1px 0 rgba(0,0,0,0.4);
    background: #ff9100;
    background: -moz-linear-gradient(top,  #0082CC 0%, #0057CC 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0082CC), color-stop(100%,#0057CC));
    background: -webkit-linear-gradient(top,  #0082CC 0%,#0057CC 100%);
    background: -o-linear-gradient(top,  #0082CC 0%,#0057CC 100%);
    background: -ms-linear-gradient(top,  #0082CC 0%,#0057CC 100%);
    background: linear-gradient(to bottom,  #0082CC 0%,#0057CC 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0082CC', endColorstr='#0057CC',GradientType=0 );
    color:white!important;
    text-align:center;
    padding: 7px 12px;
    display:inline-block;
    text-shadow:0 -1px 0 rgba(0,0,0,0.3);
    font-size: 14px;
}
a.btn:hover, #header nav a.btn:hover, .btn:hover {
    font-weight:normal;
    background: #0057CC;
    background: -moz-linear-gradient(top,  #0057CC 0%, #0057CC 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0057CC), color-stop(100%,#0057CC));
    background: -webkit-linear-gradient(top,  #0057CC 0%,#0057CC 100%);
    background: -o-linear-gradient(top,  #0057CC 0%,#0057CC 100%);
    background: -ms-linear-gradient(top,  #0057CC 0%,#0057CC 100%);
    background: linear-gradient(to bottom,  #0057CC 0%,#0057CC 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0057CC', endColorstr='#0057CC',GradientType=0 );
}

.alert {
  padding: 10px;
  margin-top: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert.success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert.error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

input[type="text"], input[type="password"] {
  border: #ddd solid 1px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 9px 8px 7px;
  vertical-align: middle;
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;

  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
}
input.error {
  background-color: #fff4f4;
  border-color: #eec1c5;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
input.error::-webkit-input-placeholder {
  color: #b94a48;
  opacity: 0.5;
}
input.error:-moz-placeholder {
  color: #b94a48;
  opacity: 0.5;
}

/* Override scrollbar */
.jspVerticalBar {
    background: none;
}
.jspTrack {
    background: none;
    border: none;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.jspDrag {
    background: rgba(0,0,0,0.2);
    width: 2px;

    -webkit-border-radius: 2px;
    border-radius: 2px;
}

@media screen and (max-width: 1022px) {
#header .wrapper {
    width: auto;
    padding: 0 20px;
}
#light-player .wrapper {
    width: 601px;
    margin: 0 auto;
}
#light-player .inner {
    margin-top: 10px;
    top: 0;
}
#light-player .watch-left-arrow, #light-player .watch-right-arrow {
  padding-top: 290px;
  height: 444px;
}
}







.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

.glyphicon-asterisk:before {
  content: "\2a";
}

.glyphicon-plus:before {
  content: "\2b";
}

.glyphicon-euro:before {
  content: "\20ac";
}

.glyphicon-minus:before {
  content: "\2212";
}

.glyphicon-cloud:before {
  content: "\2601";
}

.glyphicon-envelope:before {
  content: "\2709";
}

.glyphicon-pencil:before {
  content: "\270f";
}

.glyphicon-glass:before {
  content: "\e001";
}

.glyphicon-music:before {
  content: "\e002";
}

.glyphicon-search:before {
  content: "\e003";
}

.glyphicon-heart:before {
  content: "\e005";
}

.glyphicon-star:before {
  content: "\e006";
}

.glyphicon-star-empty:before {
  content: "\e007";
}

.glyphicon-user:before {
  content: "\e008";
}

.glyphicon-film:before {
  content: "\e009";
}

.glyphicon-th-large:before {
  content: "\e010";
}

.glyphicon-th:before {
  content: "\e011";
}

.glyphicon-th-list:before {
  content: "\e012";
}

.glyphicon-ok:before {
  content: "\e013";
}

.glyphicon-remove:before {
  content: "\e014";
}

.glyphicon-zoom-in:before {
  content: "\e015";
}

.glyphicon-zoom-out:before {
  content: "\e016";
}

.glyphicon-off:before {
  content: "\e017";
}

.glyphicon-signal:before {
  content: "\e018";
}

.glyphicon-cog:before {
  content: "\e019";
}

.glyphicon-trash:before {
  content: "\e020";
}

.glyphicon-home:before {
  content: "\e021";
}

.glyphicon-file:before {
  content: "\e022";
}

.glyphicon-time:before {
  content: "\e023";
}

.glyphicon-road:before {
  content: "\e024";
}

.glyphicon-download-alt:before {
  content: "\e025";
}

.glyphicon-download:before {
  content: "\e026";
}

.glyphicon-upload:before {
  content: "\e027";
}

.glyphicon-inbox:before {
  content: "\e028";
}

.glyphicon-play-circle:before {
  content: "\e029";
}

.glyphicon-repeat:before {
  content: "\e030";
}

.glyphicon-refresh:before {
  content: "\e031";
}

.glyphicon-list-alt:before {
  content: "\e032";
}

.glyphicon-flag:before {
  content: "\e034";
}

.glyphicon-headphones:before {
  content: "\e035";
}

.glyphicon-volume-off:before {
  content: "\e036";
}

.glyphicon-volume-down:before {
  content: "\e037";
}

.glyphicon-volume-up:before {
  content: "\e038";
}

.glyphicon-qrcode:before {
  content: "\e039";
}

.glyphicon-barcode:before {
  content: "\e040";
}

.glyphicon-tag:before {
  content: "\e041";
}

.glyphicon-tags:before {
  content: "\e042";
}

.glyphicon-book:before {
  content: "\e043";
}

.glyphicon-print:before {
  content: "\e045";
}

.glyphicon-font:before {
  content: "\e047";
}

.glyphicon-bold:before {
  content: "\e048";
}

.glyphicon-italic:before {
  content: "\e049";
}

.glyphicon-text-height:before {
  content: "\e050";
}

.glyphicon-text-width:before {
  content: "\e051";
}

.glyphicon-align-left:before {
  content: "\e052";
}

.glyphicon-align-center:before {
  content: "\e053";
}

.glyphicon-align-right:before {
  content: "\e054";
}

.glyphicon-align-justify:before {
  content: "\e055";
}

.glyphicon-list:before {
  content: "\e056";
}

.glyphicon-indent-left:before {
  content: "\e057";
}

.glyphicon-indent-right:before {
  content: "\e058";
}

.glyphicon-facetime-video:before {
  content: "\e059";
}

.glyphicon-picture:before {
  content: "\e060";
}

.glyphicon-map-marker:before {
  content: "\e062";
}

.glyphicon-adjust:before {
  content: "\e063";
}

.glyphicon-tint:before {
  content: "\e064";
}

.glyphicon-edit:before {
  content: "\e065";
}

.glyphicon-share:before {
  content: "\e066";
}

.glyphicon-check:before {
  content: "\e067";
}

.glyphicon-move:before {
  content: "\e068";
}

.glyphicon-step-backward:before {
  content: "\e069";
}

.glyphicon-fast-backward:before {
  content: "\e070";
}

.glyphicon-backward:before {
  content: "\e071";
}

.glyphicon-play:before {
  content: "\e072";
}

.glyphicon-pause:before {
  content: "\e073";
}

.glyphicon-stop:before {
  content: "\e074";
}

.glyphicon-forward:before {
  content: "\e075";
}

.glyphicon-fast-forward:before {
  content: "\e076";
}

.glyphicon-step-forward:before {
  content: "\e077";
}

.glyphicon-eject:before {
  content: "\e078";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-plus-sign:before {
  content: "\e081";
}

.glyphicon-minus-sign:before {
  content: "\e082";
}

.glyphicon-remove-sign:before {
  content: "\e083";
}

.glyphicon-ok-sign:before {
  content: "\e084";
}

.glyphicon-question-sign:before {
  content: "\e085";
}

.glyphicon-info-sign:before {
  content: "\e086";
}

.glyphicon-screenshot:before {
  content: "\e087";
}

.glyphicon-remove-circle:before {
  content: "\e088";
}

.glyphicon-ok-circle:before {
  content: "\e089";
}

.glyphicon-ban-circle:before {
  content: "\e090";
}

.glyphicon-arrow-left:before {
  content: "\e091";
}

.glyphicon-arrow-right:before {
  content: "\e092";
}

.glyphicon-arrow-up:before {
  content: "\e093";
}

.glyphicon-arrow-down:before {
  content: "\e094";
}

.glyphicon-share-alt:before {
  content: "\e095";
}

.glyphicon-resize-full:before {
  content: "\e096";
}

.glyphicon-resize-small:before {
  content: "\e097";
}

.glyphicon-exclamation-sign:before {
  content: "\e101";
}

.glyphicon-gift:before {
  content: "\e102";
}

.glyphicon-leaf:before {
  content: "\e103";
}

.glyphicon-eye-open:before {
  content: "\e105";
}

.glyphicon-eye-close:before {
  content: "\e106";
}

.glyphicon-warning-sign:before {
  content: "\e107";
}

.glyphicon-plane:before {
  content: "\e108";
}

.glyphicon-random:before {
  content: "\e110";
}

.glyphicon-comment:before {
  content: "\e111";
}

.glyphicon-magnet:before {
  content: "\e112";
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-retweet:before {
  content: "\e115";
}

.glyphicon-shopping-cart:before {
  content: "\e116";
}

.glyphicon-folder-close:before {
  content: "\e117";
}

.glyphicon-folder-open:before {
  content: "\e118";
}

.glyphicon-resize-vertical:before {
  content: "\e119";
}

.glyphicon-resize-horizontal:before {
  content: "\e120";
}

.glyphicon-hdd:before {
  content: "\e121";
}

.glyphicon-bullhorn:before {
  content: "\e122";
}

.glyphicon-certificate:before {
  content: "\e124";
}

.glyphicon-thumbs-up:before {
  content: "\e125";
}

.glyphicon-thumbs-down:before {
  content: "\e126";
}

.glyphicon-hand-right:before {
  content: "\e127";
}

.glyphicon-hand-left:before {
  content: "\e128";
}

.glyphicon-hand-up:before {
  content: "\e129";
}

.glyphicon-hand-down:before {
  content: "\e130";
}

.glyphicon-circle-arrow-right:before {
  content: "\e131";
}

.glyphicon-circle-arrow-left:before {
  content: "\e132";
}

.glyphicon-circle-arrow-up:before {
  content: "\e133";
}

.glyphicon-circle-arrow-down:before {
  content: "\e134";
}

.glyphicon-globe:before {
  content: "\e135";
}

.glyphicon-tasks:before {
  content: "\e137";
}

.glyphicon-filter:before {
  content: "\e138";
}

.glyphicon-fullscreen:before {
  content: "\e140";
}

.glyphicon-dashboard:before {
  content: "\e141";
}

.glyphicon-heart-empty:before {
  content: "\e143";
}

.glyphicon-link:before {
  content: "\e144";
}

.glyphicon-phone:before {
  content: "\e145";
}

.glyphicon-usd:before {
  content: "\e148";
}

.glyphicon-gbp:before {
  content: "\e149";
}

.glyphicon-sort:before {
  content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}

.glyphicon-sort-by-order:before {
  content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}

.glyphicon-sort-by-attributes:before {
  content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}

.glyphicon-unchecked:before {
  content: "\e157";
}

.glyphicon-expand:before {
  content: "\e158";
}

.glyphicon-collapse-down:before {
  content: "\e159";
}

.glyphicon-collapse-up:before {
  content: "\e160";
}

.glyphicon-log-in:before {
  content: "\e161";
}

.glyphicon-flash:before {
  content: "\e162";
}

.glyphicon-log-out:before {
  content: "\e163";
}

.glyphicon-new-window:before {
  content: "\e164";
}

.glyphicon-record:before {
  content: "\e165";
}

.glyphicon-save:before {
  content: "\e166";
}

.glyphicon-open:before {
  content: "\e167";
}

.glyphicon-saved:before {
  content: "\e168";
}

.glyphicon-import:before {
  content: "\e169";
}

.glyphicon-export:before {
  content: "\e170";
}

.glyphicon-send:before {
  content: "\e171";
}

.glyphicon-floppy-disk:before {
  content: "\e172";
}

.glyphicon-floppy-saved:before {
  content: "\e173";
}

.glyphicon-floppy-remove:before {
  content: "\e174";
}

.glyphicon-floppy-save:before {
  content: "\e175";
}

.glyphicon-floppy-open:before {
  content: "\e176";
}

.glyphicon-credit-card:before {
  content: "\e177";
}

.glyphicon-transfer:before {
  content: "\e178";
}

.glyphicon-cutlery:before {
  content: "\e179";
}

.glyphicon-header:before {
  content: "\e180";
}

.glyphicon-compressed:before {
  content: "\e181";
}

.glyphicon-earphone:before {
  content: "\e182";
}

.glyphicon-phone-alt:before {
  content: "\e183";
}

.glyphicon-tower:before {
  content: "\e184";
}

.glyphicon-stats:before {
  content: "\e185";
}

.glyphicon-sd-video:before {
  content: "\e186";
}

.glyphicon-hd-video:before {
  content: "\e187";
}

.glyphicon-subtitles:before {
  content: "\e188";
}

.glyphicon-sound-stereo:before {
  content: "\e189";
}

.glyphicon-sound-dolby:before {
  content: "\e190";
}

.glyphicon-sound-5-1:before {
  content: "\e191";
}

.glyphicon-sound-6-1:before {
  content: "\e192";
}

.glyphicon-sound-7-1:before {
  content: "\e193";
}

.glyphicon-copyright-mark:before {
  content: "\e194";
}

.glyphicon-registration-mark:before {
  content: "\e195";
}

.glyphicon-cloud-download:before {
  content: "\e197";
}

.glyphicon-cloud-upload:before {
  content: "\e198";
}

.glyphicon-tree-conifer:before {
  content: "\e199";
}

.glyphicon-tree-deciduous:before {
  content: "\e200";
}

.glyphicon-briefcase:before {
  content: "\1f4bc";
}

.glyphicon-calendar:before {
  content: "\1f4c5";
}

.glyphicon-pushpin:before {
  content: "\1f4cc";
}

.glyphicon-paperclip:before {
  content: "\1f4ce";
}

.glyphicon-camera:before {
  content: "\1f4f7";
}

.glyphicon-lock:before {
  content: "\1f512";
}

.glyphicon-bell:before {
  content: "\1f514";
}

.glyphicon-bookmark:before {
  content: "\1f516";
}

.glyphicon-fire:before {
  content: "\1f525";
}

.glyphicon-wrench:before {
  content: "\1f527";
}
/* End Icons */

/* Global Message */
.global-message {
    position: relative;
    z-index: 10;
    padding: 10px 20px;
    text-align: center;
    background: rgba(70, 138, 202, 0.65);
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    font-weight: bold;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
.global-message .wrapper {
}
.global-message a {
    color: #fff;
}
.global-message a:hover {
    text-decoration: underline;
}
.global-message .close {
    color: #fff;
    opacity: 1;
    width: 15px;
    font-family: sans-serif;
    font-weight: normal;
    font-size: 16px;
    padding: 0;
    margin: 0;
}
.global-message .close:hover {
    opacity: 0.8;
}
@media screen and (max-width: 612px) {
.global-message .wrapper {
    width: 100%;
}
}
/* End Global Message */

/*
     FILE ARCHIVED ON 23:35:12 Nov 24, 2013 AND RETRIEVED FROM THE
     INTERNET ARCHIVE ON 13:20:46 Jul 28, 2022.
     JAVASCRIPT APPENDED BY WAYBACK MACHINE, COPYRIGHT INTERNET ARCHIVE.

     ALL OTHER CONTENT MAY ALSO BE PROTECTED BY COPYRIGHT (17 U.S.C.
     SECTION 108(a)(3)).
*/
/*
playback timings (ms):
  captures_list: 93.007
  exclusion.robots: 0.183
  exclusion.robots.policy: 0.176
  RedisCDXSource: 3.256
  esindex: 0.006
  LoadShardBlock: 70.978 (3)
  PetaboxLoader3.datanode: 65.004 (4)
  CDXLines.iter: 16.185 (3)
  load_resource: 82.66
  PetaboxLoader3.resolve: 35.089
*/

